@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: Poppins !important;
  overflow-x: hidden !important;
}

.rootDiv {
  background-color: rgb(20, 24, 45);
  width: 100%;
}

.first-slider > div {
  backdrop-filter: blur(10px);
}

.first-slider .slick-list {
  overflow: visible;
}

.glossyBg {
  position: relative;
}
@media (max-width: 1024px) {
  .glossyBg {
    margin-top: 16vh;
  }
}

.glossyBgDetails {
  margin-top: -15vh;
  position: relative;
}
@media (max-width: 1024px) {
  .glossyBgDetails {
    margin-top: 1vh;
  }
}

.slick-prev,
.slick-next {
  z-index: 5;
}

.slick-list {
  margin: 0 -5px;
  overflow: visible;
}
.slick-slide > div {
  padding: 0 5px;
}

.slick-slide:first-child > div > div {
  padding-left: 0;
}

.slick-track {
  /* min-height: 240px; */
  margin: 0 !important;
}

.slick-disabled {
  display: none !important;
}

.slick-arrow {
  width: 28px;
  height: 28px;
}

.error {
  font-size: 12px;
  padding: 0 5px;
  color: red;
}

.country-list {
  color: black;
}

.tab-content {
  position: relative;
  background-color: #14182d;
}

.music,
.movie-details-header {
  padding-top: 0px;
}

.show-details-container {
  padding-top: 80px;
}
@media (max-width: 1024px) {
  .details-container {
    padding-top: 140px;
  }
  .movie-details-header {
    padding-top: 0px;
  }
}

.video-player-container {
  /* padding-top: 80px; */
  height: 75vh !important;
  margin-bottom: 10px;
}

.video-player-container .jwplayer.jw-flag-aspect-mode {
  height: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.slider-gradient {
  background: linear-gradient(
    to bottom right,
    #04050f 17.76%,
    rgba(0, 0, 0, 0.687449) 41.44%,
    rgba(6, 0, 0, 0) 100%
  );
  width: inherit;
  position: absolute;
  height: inherit;
}

.skelton-container span {
  display: flex;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.border-bottom-line {
  border-bottom: 1px solid #292626;
}

.font-size-18 {
  font-size: 18px;
}

.wtp-70 {
  width: 70% !important;
}

.wtp-22 {
  width: 22% !important;
}

.album-details-main-view {
  background-color: #14182d;
  padding: 0 30px 90px;
}

.react-tel-input .form-control {
  width: inherit;
}

::-webkit-scrollbar {
  width: 6px;
}

/* ::-webkit-scrollbar-button {
  background: #ccc;
} */
::-webkit-scrollbar-track-piece {
  background: #888;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}

.audio-player-container {
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 9999;
}

.rhap_container {
  background-color: #14182d !important;
}

.rhap_time {
  color: #fff !important;
}

.rhap_main-controls button {
  color: #fff !important;
}

.rhap_additional-controls button {
  display: none !important;
}

.rhap_progress-filled {
  background-color: #c00a2e !important;
}

.rhap_volume-container button {
  color: #fff !important;
}

.rhap_volume-container {
  margin: 0 calc(0px + 1%) !important;
}

/* .rhap_container {
  padding: 0 !important;
} */

.rhap_volume-controls,
.rhap_progress-section {
  padding-right: 20px !important;
  color: #fff !important;
}

.react-loading-skeleton {
  z-index: 0;
}

.pt-11 {
  padding-top: 11vh;
}

.pt-35 {
  padding-top: 35vh;
}

.accordion-button::after {
  background-image: url('../assets/collapse-open.svg') !important;
  background-size: inherit;
}

.ellipse {
  width: 200px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid black;
  overflow: hidden;
}

.text {
  display: inline-block;
  line-height: 100px;
  text-align: center;
  width: 200px;
  height: 100px;
  transform: translateX(-50%);
}
