.layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  margin: 0;
}
.layout-footer-container {
  display: flex;

  width: 100%;
}
.layout-children-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 100%;
  min-height: 80vh;
}
