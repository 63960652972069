.movie-details {
  .image-container {
    position: relative;
    max-width: 100%;
    height: 100%;
  }

  .image-container img {
    width: 100vw !important;
    // max-width: 100%;
  }

  .overlay-bg {
    background: rgb(4, 5, 15);
    background: linear-gradient(90deg,
        rgba(4, 5, 15, 0.9192270658263305) 0%,
        rgba(0, 0, 0, 0.759563200280112) 34%,
        rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .overlay-text {
    width: 40%;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    // text-shadow: 2px 2px 2px #000;
    padding: 10px;
    // background-color: rgba(0, 0, 0, 0.5);
  }

  .episodes-container {
    max-height: 60vh !important;
    overflow-y: scroll !important;
  }
}
