:root {
  --default-card-width: calc(100vw / 9);
  --default-card-height: 250px;
  --default-card-padding: 0;
  --details-card-width: 260px;
  --details-card-height: 170px;
  --recommendations-card-width: 240px;
  --recommendations-card-height: 130px;
}

@media (min-width: 1201px) {
  :root {
    --default-card-padding: 0;
    --default-card-width: calc(100vw / 9);
    --details-card-width: calc(calc(100% - 10px) / 1);
    --recommendations-card-width: calc(calc(100vw - 100px) / 5.5);
    --recommendations-card-height: 140px;
  }
}
@media (max-width: 1200px) {
  :root {
    --default-card-padding: 0 10px;
    --default-card-width: calc(100vw / 7);
    --default-card-height: 220px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 4.6);
    --recommendations-card-height: 140px;
  }
}
@media (max-width: 992px) {
  :root {
    --default-card-padding: 0 10px;
    --default-card-width: calc(100vw / 6);
    --default-card-height: 200px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 3.7);
    --recommendations-card-height: 120px;
  }
}
@media (max-width: 768px) {
  :root {
    --default-card-padding: 0 10px;
    --default-card-width: calc(100vw / 5);
    --default-card-height: 180px;
    // --details-card-width: 0px;
    //  --details-card-height: 120px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 2.7);
    --recommendations-card-height: 110px;
  }
}
@media (max-width: 576px) {
  :root {
    --default-card-padding: 0 10px;
    --default-card-width: calc(100vw / 4);
    --default-card-height: 180px;
    --details-card-width: 240px;
    --details-card-height: 140px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 2.7);
    --recommendations-card-height: 110px;
  }
}
@media (max-width: 480px) {
  :root {
    --default-card-padding: 0 15px;
    --default-card-height: 180px;
    --default-card-width: calc(100vw / 3);
    --details-card-width: 220px;
    --details-card-height: 140px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 1.7);
    --recommendations-card-height: 80px;
  }
}
@media (max-width: 340px) {
  :root {
    --default-card-padding: 0 15px;
    --default-card-width: calc(100vw / 2);
    --default-card-height: 180px;
    --details-card-width: 220px;
    --details-card-height: 140px;
    --recommendations-card-width: calc(calc(100vw - 100px) / 1.5);
    --recommendations-card-height: 80px;
  }
}

.generic-card {
  padding: var(--default-card-padding);
  width: var(--default-card-width);
  height: var(--default-card-height);
  max-width: 180px;
}
.generic-card > div > div {
  height: var(--default-card-height);
}

.details-card {
  width: var(--details-card-width);
}
.details-card > div > div {
  height: var(--details-card-height);
}

.recommendations-card {
  width: var(--recommendations-card-width);
  height: var(--recommendations-card-height);
}
.recommendations-card > div > div {
  height: var(--recommendations-card-height);
  height: var(--recommendations-card-height);
}
