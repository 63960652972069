.site-footer {
  background-color: #0b0d1c;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  width: 100%;
  color: white;
  bottom: 0;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
  font-family: inherit;
  font-weight: 400;
  padding: 3px 0;
  opacity: 0.8;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 12px;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}

.site-footer .social-icons {
  text-align: left;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}

.copyright-container {
  display: flex;
  justify-content: center;

  .copyright-text {
    justify-self: center;
    margin: 0;
  }
}

.footer-item {
  text-decoration: underline;

  cursor: pointer;
}

@media (max-width: 991px) {
  .site-footer [class^='col-'] {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}

.social-icons-container {
  display: flex;
  flex-direction: row;

  .social-icon {
    height: 50px;
    width: 50px;
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

.success-container {
  display: flex;
  background-color: #0b0d1c;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  width: 100%;
  height: 1000px;
}

.app-icons {
  display: flex;
  align-items: center;
}


.android {
  width: 150px;
}

.apple {
  width: 150px;
}
