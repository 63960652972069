/* Extra small devices (portrait phones, 375px and up) */
.series-card {
  width: 100% !important;
}

@media (min-width: 375px) {
  .top-playlist-card {
    .col-3 {
      width: 20% !important;
    }
    .col-6 {
      width: 55% !important;
    }

    .dm-none {
      display: none !important;
    }
    .title {
      font-size: 12px !important;
    }
  }

  .audio-player-container {
    .dm-none {
      display: none !important;
    }
  }

  .movie-details {
    .movie-card {
      width: 160px !important;
      cursor: pointer;
    }
    .overlay-text {
      width: 80% !important;
      font-size: 18px !important;
    }
    .title {
      font-size: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
    .details-margin {
      margin: 0px 20px;
    }
    .image-container img {
      height: 250px !important;
    }
    .video-player-container {
      height: 250px !important;
    }

    .desktop-view {
      display: none !important;
    }
    .mobile-view {
      display: block !important;
    }
  }

  .series-details {
    .col-4 {
      width: 40% !important;
    }
    .col-6 {
      width: 55% !important;
    }
    .series-card {
      width: 100% !important;
      cursor: pointer;
    }
    .desktop-view {
      display: none !important;
    }
    .mobile-view {
      display: block !important;
    }
  }
}

@media (min-width: 425px) {
  .movie-details {
    .movie-card {
      width: 190px !important;
    }
    .overlay-text {
      width: 80% !important;
      font-size: 18px !important;
    }
  }
  // .series-details {
  //   justify-content: space-between !important;
  //   .col-4 {
  //     width: 45% !important;
  //   }
  //   .col-6 {
  //     width: 55% !important;
  //   }
  //   .series-card {
  //     width: 100% !important;
  //     cursor: pointer;
  //   }
  // }
}

@media (min-width: 500px) {
  .movie-details {
    .movie-card {
      width: 220px !important;
    }
  }

  .series-details {
    .col-4 {
      width: 40% !important;
    }
    .col-6 {
      width: 50% !important;
    }
    .series-card {
      width: 100% !important;
      cursor: pointer;
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .album-card {
    .col-sm-4 {
      width: 40% !important;
    }
    .col-sm-8 {
      width: 60% !important;
    }
  }
  .movie-details {
    .movie-card {
      width: 160px !important;
    }
    .overlay-text {
      width: 70% !important;
      font-size: 18px !important;
    }
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .top-playlist-card {
    .col-md-1 {
      width: 10% !important;
    }
    .col-md-2 {
      width: 8% !important;
    }
    .col-md-3 {
      width: 37% !important;
    }
    .col-md-4 {
      width: 25% !important;
    }
    .dm-none {
      display: block !important;
    }
    .title {
      font-size: 10px !important;
    }
  }

  .album-card {
    .col-md-4 {
      width: 40% !important;
    }
    .col-md-8 {
      width: 60% !important;
    }
  }

  .movie-details {
    .movie-card {
      width: 220px !important;
    }
    .overlay-text {
      width: 50% !important;
      font-size: 18px !important;
    }
    .title {
      font-size: 36px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .image-container img {
      height: 300px !important;
    }
    .video-player-container {
      height: 300px !important;
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }

    .desktop-view {
      display: none !important;
    }
    .mobile-view {
      display: block !important;
    }
  }

  .series-details {
    .col-4 {
      width: 40% !important;
    }
    .col-6 {
      width: 55% !important;
    }
    .series-card {
      width: 100% !important;
      cursor: pointer;
    }
    .desktop-view {
      display: none !important;
    }
    .mobile-view {
      display: block !important;
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .top-playlist-card {
    .col-lg-1 {
      width: 6% !important;
    }
    .col-lg-2 {
      width: 24% !important;
    }
    .col-lg-3 {
      width: 30% !important;
    }
    .dm-none {
      display: block !important;
    }
    .title {
      font-size: 14px !important;
    }
  }

  .album-card {
    .col-lg-2 {
      width: 30% !important;
    }
    .col-lg-6 {
      width: 60% !important;
    }
    .col-lg-3 {
      width: 30% !important;
    }
  }

  .audio-player-container {
    .dm-none {
      display: block !important;
    }
    .audio-title {
      width: 30vw;
      white-space: nowrap; /* prevent the text from wrapping */
      overflow: hidden; /* hide any overflow */
      text-overflow: ellipsis; /* add the ellipsis */
      font-size: 14px;
      font-weight: 200;
    }
    .audio-description {
      width: 30vw;
      white-space: nowrap; /* prevent the text from wrapping */
      overflow: hidden; /* hide any overflow */
      text-overflow: ellipsis; /* add the ellipsis */
      font-size: 12px;
      font-weight: 100;
    }
  }

  .movie-details {
    .image-container {
      height: 70vh !important;
    }
    .movie-card {
      width: 220px !important;
    }
    .overlay-text {
      width: 40% !important;
      font-size: 18px !important;
    }
    .title {
      font-size: 36px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }

    .video-player-container {
      height: 70vh !important;
    }

    .image-container img {
      height: 70vh !important;
    }

    .details-margin {
      margin: 0px 40px;
    }
    .desktop-view {
      display: block !important;
    }
    .mobile-view {
      display: none !important;
    }
  }

  .series-details {
    .col-4 {
      width: 18% !important;
    }
    .col-6 {
      width: 60% !important;
    }
    .series-card {
      width: 100% !important;
      cursor: pointer;
    }
  }
}
