.mb-header-container {
  position: fixed;
  width: 100%;
  height: 130px; /* 16% of the viewport height */
  z-index: 100;
  padding: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: rgb(20, 24, 45) !important;
  padding: 5px;
}

.logo-layout {
  padding: 0 10px;
}
.logo-layout .logo {
  width: 50px;
  height: 50px;
}

.mb-header-container > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.profile-section {
  height: 60%;
  padding: 0 10px;
  justify-content: space-between;
}

.tabs-section {
  height: 40%;
  padding: 0;
  overflow-x: auto;
}

.category-chips {
  color: white !important;
  font-weight: normal !important;
  margin-top: 0 !important;
  border: #393b4d !important;
}

.header-right-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  padding: 10px;
}

.search-input {
  width: 10px;
  background-color: #14182d;
  color: white;
  margin: 10px;
  height: 40px;
  border-radius: 18px;
  outline: none;
  padding-left: 40px;
  border: 1px solid #14182d;
  background-size: 20px;
  background-image: url('../../assets/search-icon.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-clip: padding-box;
}

.search-input:focus {
  width: 70%;
  border: 1px solid white;
}

.user-logo {
  height: 80;
  width: 80;
  border-radius: 30;
  color: white;
}
.header-right-container a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 10px;
}
.col-10::-webkit-scrollbar {
  display: none; /* for Webkit browsers */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* ::-webkit-scrollbar-button {
  background: #ccc;
} */
::-webkit-scrollbar-track-piece {
  background: #888;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}
