.container__slider {
  position: relative;
  width: 100%;
  height: 75vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 1024px) {
    height: 40vh;
    top: 120px;
  }

  @media (max-width: 600px) {
    height: 35vh;
    top: 120px;
  }

}

.container__slider > button {
  background-color: transparent;
  outline: none;
  border: 0;
  color: whitesmoke;
  font-size: 24px;
}

.container__slider > button:active {
  color: black;
}

.slider__btn-next {
  position: absolute;
  right: 0;
}

.slider__btn-prev {
  position: absolute;
  left: 0;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  transform: translateX(0);
  transition: transform 1s ease;
  cursor: pointer;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5 {
  transform: translateX(-400%);
}

.slider__item-active-6 {
  transform: translateX(-500%);
}

.slider__item-active-7 {
  transform: translateX(-600%);
}

.slider__item img {
  width: 100%;
  height: 100%;
}
