:root {
  --continue-card-width: calc(100vw / 9);
  --continue-card-height: 220px;
  --continue-card-padding: 0;
}

@media (min-width: 1200px) {
  :root {
    --continue-card-padding: 0;
    --continue-card-width: calc(100vw / 6.5);
  }
}
@media (max-width: 1200px) {
  :root {
    --continue-card-padding: 0 10px;
    --continue-card-width: calc(100vw / 5.5);
    --continue-card-height: 200px;
  }
}
@media (max-width: 992px) {
  :root {
    --continue-card-padding: 0 10px;
    --continue-card-width: calc(100vw / 4.5);
    --continue-card-height: 180px;
  }
}
@media (max-width: 768px) {
  :root {
    --continue-card-padding: 0 10px;
    --continue-card-width: calc(100vw / 3.5);
    --continue-card-height: 180px;
  }
}
@media (max-width: 576px) {
  :root {
    --continue-card-padding: 0 10px;
    --continue-card-width: calc(100vw / 2.5);
    --continue-card-height: 180px;
  }
}
@media (max-width: 480px) {
  :root {
    --continue-card-padding: 0 15px;
    --continue-card-width: calc(100vw / 2.5);
    --continue-card-height: 160px;
  }
}
@media (max-width: 340px) {
  :root {
    --continue-card-padding: 0 15px;
    --continue-card-width: calc(100vw / 2);
    --continue-card-height: 160px;
  }
}

.continue-card {
  padding: var(--continue-card-padding);
  width: var(--continue-card-width);
  height: var(--continue-card-height);
  max-width: 180px;
}
