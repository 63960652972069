.header-container {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  color: white;
  position: fixed;
  width: 100%;
  height: 7.5vh;
  z-index: 100;

  .header-style {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo-layout {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 10px;

      .logo {
        width: 70px;
        height: 55px;
        padding: 10px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        .logo {
          padding: 15px 20px;
        }
      }
    }

    .header-category-list {
      display: flex;
      flex-direction: row;

      .header-category-item {
        font-size: 16px;
        font-weight: 500;
        color: white;
        opacity: 0.75;
        padding: 20px 14px;
        cursor: pointer;

        &.active {
          font-weight: 700;
          opacity: 2;
        }
      }
    }

    .header-right-container {
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: flex-end;
      padding: 10px;

      .search-input {
        width: 320px;
        background-color: #14182d;
        color: white;
        margin: 10px;
        height: 40px;
        border-radius: 18px;
        outline: none;
        padding-left: 40px;
        border: 1px solid #14182d;
        background-size: 20px;
        background-image: url('../../../search-icon.svg');
        background-repeat: no-repeat;
        background-position: 10px center;
        background-clip: padding-box;
        border: 1px solid white;
      }

      .user-logo {
        height: 80;
        width: 80;
        border-radius: 30;
        color: white;
      }

      a {
        color: white;
        text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        padding: 0 10px;
      }
    }
  }
}

.header-container.scrolled {
  background-color: #14182d;
  transition: background-color 0.3s;
}

.solidBg {
  background-color: #14182d !important;
}

.gradientBg {
  // background: linear-gradient(rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0));
  background: linear-gradient(180deg, #0b0f16 20.83%, rgba(11, 15, 22, 0) 100%);
  backdrop-filter: blur(4px);
}

.profile-header {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
